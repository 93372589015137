import { PurpleButton } from "../mui/Buttons";
import { PrimaryText } from "../mui/Typography";
import React, { useEffect, useState } from 'react';
import { TextInput } from "../mui/Inputs";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { Delete } from "@mui/icons-material";


export default function EmailLogRow({index,listing,deleteFunc}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [updateOpen,setUpdateOpen] = useState(false)

    const [type,setType] = useState(listing.responseType)
    const [details,setDetails] = useState(listing.responseDetail)

    const typeOptions = ["Positive","Negative","Other"]

    const update = () => {
        if(updateOpen)
        {
            updateDB()
        }
        else{
            setUpdateOpen(true)
        }
    }

    const updateDB = () => {
        axios.get(baseURL+'updateListingEmailLog',{
            params:{
                logID:listing.id,
                resType:type,
                resDetail:details
            }
        }).then((response) => {
            
            setUpdateOpen(false)
        })
    }

    const deleteRow = () => {
        axios.get(baseURL+'deleteListingEmailLog',{
            params:{
                logID:listing.id,
            }
        }).then((response) => {
            
            deleteFunc(index)
        })
    }


    return(
        <div className={`scrapedRow ${index%2==0?"purple":""}`}>

            <PrimaryText className='emailRowText' variant='subtitle1'>{listing.gymName}</PrimaryText>

            <PrimaryText className='emailRowText' variant='body1'>Template: {listing.template}</PrimaryText>
            <PrimaryText className='emailRowText' variant='body1'>{listing.country}</PrimaryText>
            <PrimaryText className='emailRowText' variant='body1'>{type}</PrimaryText>

            {listing.responseType!=="None" && <PrimaryText className='emailRowText' variant='body1'>{details}</PrimaryText>}

            {updateOpen && <Autocomplete className='emailRowText' disablePortal defaultValue={type} options={typeOptions} renderInput={(params)=><TextInput {...params} variant="outlined" label="Response Type" value={"Angola"} />} onChange={(e) => setType(e.target.textContent)}/>}

            {updateOpen && <TextInput className='emailRowText' variant="outlined" label="Details"
                        value={details} onChange={(e) => setDetails(e.target.value)}/>}

            <PurpleButton variant="contained" size="small" onClick={update}>Update</PurpleButton>

            <Delete className='binIcon' onClick={deleteRow}/>
        </div>
    )

}