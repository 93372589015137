import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../styling/header.css'
import { PurpleButton } from "./mui/Buttons";
import { PrimaryText } from "./mui/Typography";
import { ConfirmationNumberOutlined, FingerprintRounded, LogoutRounded } from "@mui/icons-material";
import { auth } from "..";

export default function Header({loggedIn}) {


    const [optionsOpen,setOptionsOpen] = useState(false)


    const navigate = useNavigate();



    const routeSelected = (route) => {
        
        setOptionsOpen(false)
        navigate(`../${route}`, {   });
    }

    const optionsClicked = () =>{
        console.log("OPTION CLOKCED")
        setOptionsOpen(!optionsOpen)
        // logout()
    }

    const logout = () => {
        // const auth = getAuth();
        
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
            navigate("../login", {   });
          }).catch((error) => {
            // An error happened.
            console.log("ERROR", error.message)
          });
    }


    return(
        <div className="header">
            
            {/* <span className="headerTest">Test Mode</span> */}

            <div className="fullScreen">
                <div className="container">
                    <div className="headerRow">

                        <img className="logoHeader" src="/assets/gymExPortalLogo.png" />



                        <div className="buttonsRowHeader">
                            
                            {!loggedIn && <PurpleButton variant="outlined" size="medium" onClick={()=>routeSelected('login')}>Sign In</PurpleButton>}

                            {loggedIn && <PurpleButton variant="outlined" size="medium" onClick={logout}>Log Out</PurpleButton>}

                            

                            {/* <span>HELP</span> */}

                        </div>




                    </div>
                </div>
            </div>

            


        </div>
    )
}


