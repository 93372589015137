import React, { useEffect, useState } from 'react';
import { PurpleButton, PurpleOutlineButton } from '../mui/Buttons';
import { PurpleCheckBox, PurpleSwitch, TextInput } from '../mui/Inputs';
import { ErrorText, GreenText, PrimaryText } from '../mui/Typography';
import axios from "axios";
import {useNavigate,useLocation,useParams} from 'react-router-dom';


export default function AdminListingRow({listing,index,updated,checkedP}) {

    const [allListings,setAllListings] = useState([])
    const [filteredListings,setFilteredListings] = useState([])

    const [checked,setChecked] = useState(checkedP)
    
    const [live,setLive] = useState(listing.live)
    const [hasAccount,setHasAccount] = useState(listing.hasAccount===undefined?false:listing.hasAccount)
    const [emailSent,setEmailSent] = useState(listing.sentEmail)
    const [template,setTemplate] = useState(0)
    const [emailSentDate,setEmailSentDate] = useState(new Date(listing.sentEmailDate))

    console.log("HAS ACCOUNT",listing.hasAccount)

    if(listing.sentEmail)
    {
        console.log("ESD",listing.sentEmailDate)
    }
     
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const navigate = useNavigate();
   
    useEffect(() => {
        
      }, []);

      useEffect(() => {
        setChecked(checkedP)
    }, [checkedP]);


    const checkBoxChanged = (event,value) => {
        let isChecked = event.target.checked 
        setChecked(isChecked)
        
        updated(listing.id,isChecked)
    }

    const viewProfile = () => {

        let url ='https://portal.gymexplorer.fit/listing/'+listing.id
        window.open(url, '_blank', 'noopener,noreferrer');
        // navigate("../listing/"+listing.id, {state:{gym:listing}});
    }

    const createAccount = () => {

        axios.post(baseURL+'makeGymAccount',{
        
            gymID:listing.id
    

        }).then((response) => {
            
            let resVar = response.data
            if(resVar.error)
            {
                console.log("Error Creating Account", resVar.errorMessage)
            }
            else{
                listing.loginUpdated = false
                setHasAccount(true)
            }

        })
    }


    const sendEmail = () => {
        axios.post(baseURL+'sendGymListingEmail',{
            gymID:listing.id,
            templateNum:template
            
        }).then((response) => {
            
            let resVar = response.data
            if(resVar.error)
            {
                console.log("Error Sending Email",resVar.errorMessage)
            }
            else{
                setEmailSentDate(new Date())
                setEmailSent(true)
            }
        })
    }

    const changeLive = (liveP) => {

        setLive(liveP)
        axios.post(baseURL+'makeGymLive',{
        
            gymID:listing.id,
            live:liveP

        }).then((response) => {
            
            let resVar = response.data
            console.log("RES",resVar)
        })
    }

    

    const visitDB = () => {
        let url ='https://console.firebase.google.com/u/0/project/travel-fitness-pass/firestore/data/~2Fgyms~2F'+listing.id
        window.open(url, '_blank', 'noopener,noreferrer');
    }
      
    return(

        <div className={`scrapedRow ${index%2==0?"purple":""}`}>
            
            <PurpleCheckBox  onChange={event =>checkBoxChanged(event)} checked={checked}/>

            <PrimaryText className='adminRowText' variant='subtitle1'>{listing.name}</PrimaryText>
            <PrimaryText className='adminRowTextAddress' variant='body1'>{listing.address}</PrimaryText>
            
            <div className='adminLiveSwitchRow'>
                {live && <GreenText variant='body1'>Live</GreenText>}
                {!live && <ErrorText variant='body1'>Not live</ErrorText> }
                <PurpleSwitch checked={live} onChange={(e)=>changeLive(e.target.checked)} disabled={!hasAccount}/>
            </div>
            

            {!emailSent && <PrimaryText className='adminRowText' variant='body1'>{"Not Contacted"}</PrimaryText>}
            {emailSent && <PrimaryText className='adminRowText' variant='body1'>{"Contacted: "+emailSentDate.toLocaleDateString("en-IE")}</PrimaryText>}
            
            {!hasAccount && <PrimaryText className='adminRowText' variant='body1'>{"No Account"}</PrimaryText>}
            {hasAccount && <PrimaryText className='adminRowText' variant='body1'>{listing.loginUpdated?"Logged In":"Not Logged In"}</PrimaryText>}

            
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={viewProfile}>View Profile</PurpleOutlineButton>
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={createAccount} disabled={hasAccount}>Create Account</PurpleOutlineButton>
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={visitDB}>Open DB</PurpleOutlineButton>
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={sendEmail} disabled={(!hasAccount && template!=="7") || emailSent}>Send Email</PurpleOutlineButton>
            <TextInput className='portalEditTemplate' variant="outlined" label="Template"
                value={template} onChange={(e) => setTemplate(e.target.value)}/>
            

        </div>
    )
}