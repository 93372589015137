

import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/system';





export default function OpenHourRowEL({index,openP,edit,closeP,deleteFunc}) {

    const [open,setOpen] = useState(openP)
    const [close,setClose] = useState(closeP)
    
    

    return(

        <div className='OHInfoRow'>
            <div className={`portalEditOHRow ${index==0?'':'bin'}`}>


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                    displayStaticWrapperAs="mobile"
                    label="Open Time"
                    value={open}
                    minutesStep={5}
                    onChange={(newValue) => {
                    console.log("NEW TIME",new Date(newValue))
                    setOpen(new Date(newValue));
                    edit(index,{open:new Date(newValue),close:close})
                    }}
                    renderInput={(params) => <TextInput {...params} />}
                />
                </LocalizationProvider>


                <PrimaryText>-</PrimaryText>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                    label="Close Time"
                    value={close}
                    
                    onChange={(newValue) => {
                    setClose(new Date(newValue));
                    edit(index,{open:open,close:new Date(newValue)})
                    }}
                    renderInput={(params) => <TextInput  {...params} />}
                />
                </LocalizationProvider>



                {index!==0 && <Box className='binIcon'><Delete onClick={() => deleteFunc(index)}/></Box>}
                {index!==0 && <Box className='binIconMobile'><Delete onClick={() => deleteFunc(index)}/></Box>}

            </div>

        </div>
    )
}