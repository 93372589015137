

import { FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { Checkbox } from 'semantic-ui-react';
import { PurpleButton } from '../mui/Buttons';
import { CheckBoxLabel1, PurpleCheckBox, TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';




export default function MultipleChoiceEL({type,answersP,returnAnswer}) {



    const equipmentArray = ['Spin Bikes','Free Weights', "Treadmills","Bench Press","Squat Rack",'Stair Master','Cross Trainers','Rowing Machine','Smith Machine','Steppers','Pec Deck','Ski Ergs','Leg Press','Cable Crossover','Preacher Curl Machine','Calf Raise Machine']
    const amenitiesArray = ['Swimming Pool','Yoga Studio','Changing Rooms','Lockers','Showers','Sauna','Steam Room','Boxing Area','Pilates Studio','Basketball Courts','Tennis Courts']

    const [newOptionText,setNewOptionText] = useState("")

    const [optionsArray,setOptionsArray] = useState([])
    const [answers,setAnswers] = useState(answersP)
    const [stateIndex,setStateIndex] = useState(0)

    if(optionsArray.length === 0)
    {
        if(type === 'equipment'){setOptionsArray(equipmentArray)}
        if(type === 'amenities'){setOptionsArray(amenitiesArray)}
    }
    

    const checkBoxChanged = (event,value) => {
        let isChecked = event.target.checked 
        
        if(isChecked){

            answers.push(value)
            setAnswers(answers)
            
            returnAnswer(answers)

        }else{
            let removed = answers.filter(e => e !== value)
            setAnswers(removed)
            
            returnAnswer(removed)
        }

        setStateIndex(stateIndex+1)
        
    }

    const showOptions = () => 
    {
        let optArray = []

        optionsArray.forEach(option => {
            optArray.push(
                <div className='multipleChoiceAnswer'>
                    <PurpleCheckBox  onChange={event =>checkBoxChanged(event ,option)} checked={answers.includes(option)}/>
                    <PrimaryText variant='body1'>{option}</PrimaryText>
                </div>
            )
        });

        return optArray
    }

    const addOption = () => {
        optionsArray.push(newOptionText)
        setOptionsArray(optionsArray)

        answers.push(newOptionText)
        setAnswers(answers)
        returnAnswer(answers)

        setNewOptionText("")
    }



    return(

        <div className="questionComponent">
            


            

            <div className='editListingML'>
                
                {showOptions()}

            </div>
            <div className='multipleOptionOther'>
                <TextInput className='portalEditInput' variant="outlined" label="Other"
                            value={newOptionText} onChange={(e) => setNewOptionText(e.target.value)}/>
                <PurpleButton className='addOptionBtn' size='medium' disabled={newOptionText===""} onClick={addOption}>Add Option</PurpleButton>
            </div>
            
        </div>
    )
}