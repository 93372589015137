


import { Error, ExpandLess, ExpandMore, Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Autocomplete, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FacilityInfoEL from '../components/editListing/FacilityInfoEL';
import GeneralInfoEL from '../components/editListing/GeneralInfoEL';
import ImagesEL from '../components/editListing/ImagesEL';
import OpeningHoursEL from '../components/editListing/OpenHoursEL';


import SocialMediaEL from '../components/editListing/SocialMediaEL';
import { PurpleButton } from '../components/mui/Buttons';
import { PurpleSwitch, TextInput } from '../components/mui/Inputs';
import { ErrorText, GreenText, PrimaryText, SecondaryText } from '../components/mui/Typography';
import {useNavigate,useLocation,useParams} from 'react-router-dom';

import '../styling/editListing.css'
import axios from "axios";
import { auth } from '..';
import { onAuthStateChanged, signOut } from 'firebase/auth';



export default function EditListing({}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [loading,setLoading] = useState(false)
    const [gym,setGym] = useState(null)
    const [live,setLive] = useState(false)

    const location = useLocation();
    const navigate = useNavigate();
    let { gymID } = useParams();

    useEffect(() => {
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
              loadGym()
            } else {
                console.log("No user sorry")
            }
          })
          return () => unsubscribe()

    


        
        
      }, []);


      const loadGym = () => {
        if(gym === null)
        {
            if(location.state !== null)
            {
                let gym = location.state.gym
                gym.passes = adjustPassesPrice(gym.passes)
                setGym(gym)
                setLive(gym.live)
            }
            else{
                
                loadGymFromID(gymID)
            }
        }
      }

    
    const loadGymFromID = (gymID) => {


        if(auth.currentUser !== null)
        {

            setLoading(true)
            let uid = auth.currentUser.uid

            axios.get(baseURL+'loadGymByID',{
            
                params:{
                    gymID:gymID,
                    uid:uid
                }

            }).then((response) => {
                
                let resVar = response.data
                let gym = resVar.gym
                let admin = resVar.admin

                if(gym.loginUpdated || admin){
                    gym.passes = adjustPassesPrice(gym.passes)
                    setGym(gym)
                    setLoading(false)
                    setLive(gym.live)
                }
                else{
                    console.log("NO USER OTHER")
                    navigate("../login", {state:{}});
                }
            })
        }else{
            console.log("No user")
        }
    }

    const logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
            navigate("../login", {state:{}});
          }).catch((error) => {
            // An error happened.
            console.log("ERROR", error.message)
          });
        
    }
    
    const adjustPassesPrice = (passes) => {

        console.log("Before Passes",passes)

        passes.forEach(pass => {
            pass.price = pass.price/100
        });

        console.log("Updated Passes",passes)
        return passes
    }

    
    const changeLive = (liveP) => {

        
        axios.post(baseURL+'makeGymLive',{
        
            gymID:gym.id,
            live:liveP

        }).then((response) => {
            
            setLive(liveP)
            let resVar = response.data
            console.log("RES",resVar)
        })
    }


    return(

        <div className="portalEditScreen">
            <div className='fullScreen'>
                <div className='container'>
                    
                {loading && <div className='loginLoading'>
                    <div className='loginLoadingBox'>
                        <PrimaryText variant='h4'>Loading Profile</PrimaryText>
                        <CircularProgress sx={{color:'#766CE3', height:'500'}} className='loginLoadingCircle'/>
                    </div>
                </div>}

                    {gym!==null && <div className='portalEditContent'>

                        {/* <button onClick={logout}>Log Out</button> */}

                        <div className="passOverviewHeaders">
                            <PrimaryText variant="h3">YOUR LISTING</PrimaryText>
                            <SecondaryText variant="subtitle1">Make changes and additions to your listing below</SecondaryText>
                        </div>

                        <div className="passOverviewHeaders">
                            <PrimaryText variant="h4">Status</PrimaryText>
                            <SecondaryText variant="subtitle2">If set to live, your listing will appear to users on our wesbite.</SecondaryText>
                            
                            <div className='adminLiveSwitchRow profile'>
                                
                                {!live && <ErrorText variant='subtitle3'>Not live</ErrorText> }
                                {live && <GreenText variant='subtitle3'>Live</GreenText>}
                                <PurpleSwitch checked={live} onChange={(e)=>changeLive(e.target.checked)}/>
                                
                            </div>
                        </div>

                        

                        <GeneralInfoEL gym={gym} refreshP={loadGymFromID}/>

                        <ImagesEL gym={gym}/>

                        <SocialMediaEL gym={gym}/>

                        <OpeningHoursEL gym={gym}/>
                        
                        <FacilityInfoEL gym={gym}/>

                    </div>}

                
                </div>
            </div>
        </div>
    )
}