import React, { useEffect, useState } from 'react';
import { PurpleButton, PurpleOutlineButton } from '../mui/Buttons';
import { PurpleCheckBox, TextInput } from '../mui/Inputs';
import { PrimaryText } from '../mui/Typography';
import axios from "axios";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';


export default function AdminScrapedRow({listing,index,updated,city,checkedP}) {

    const [allListings,setAllListings] = useState([])
    const [filteredListings,setFilteredListings] = useState([])

    const [issue,setIssue] = useState(listing.issue || 'No passes')

    const [checked,setChecked] = useState(checkedP)
    const [emailSent,setEmailSent] = useState(listing.messaged)
    const [emailSentDate,setEmailSentDate] = useState(new Date(listing.messageTime))

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
   
    const issueOptions   =  ['None','No passes','Not interested','Invalid Business','Duplicate Email']

    useEffect(() => {
        setIssue(listing.issue || 'None')
        setEmailSent(listing.messaged)
        if(index == 0 ){console.log('listing updated', listing.name)}    
      }, [listing.id]);

    //   useEffect(() => {
    //         console.log('CITY',index)
    // }, [city]);

    useEffect(() => {
        setChecked(checkedP)
    }, [checkedP]);




    const checkBoxChanged = (event,value) => {
        let isChecked = event.target.checked 
        setChecked(isChecked)
        
        updated(listing.id,isChecked)
    }

    const createGym = () => {

        axios.get(baseURL+'convertScrapedListing',{
        
            params:{
                scraperID:listing.id
            }

        }).then((response) => {
            
            let resVar = response.data
            console.log("CREATED GYM",resVar)

            let url ='https://portal.gymexplorer.fit/listing/'+resVar
            window.open(url, '_blank', 'noopener,noreferrer');

        })
    }

    const sendEmail = () => {

        axios.get(baseURL+'sendGymInitialEmail',{
        
            params:{
                gymID:listing.id,
                name:listing.name,
                email:listing.email,
                city:listing.city,
                address:listing.address
            }

        }).then((response) => {
            
            let resVar = response.data
            console.log("CERATED GYM",resVar)

        })
    }

    const updateIssueDB = (issueP) => {

        axios.get(baseURL+'updateScrapedIssue',{
        
            params:{
                scraperID:listing.id,
                issue:issueP
            }

        }).then((response) => {
            
            let resVar = response.data
            console.log("ISSUE RES",resVar)

        })
    }

    const visitDB = () => {
        let url ='https://console.firebase.google.com/u/0/project/travel-fitness-pass/firestore/data/~2FgymScrapeResults~2F'+listing.id
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const issueChanged  =  (issueP)  => {
        setIssue(issueP)
        updateIssueDB(issueP)
    }

      
    return(

        <div className={`scrapedRow ${index%2==0?"purple":""}`}>
            
            <PurpleCheckBox  onChange={event =>checkBoxChanged(event)} checked={checked}/>

            <PrimaryText className='adminRowText' variant='subtitle1'>{listing.name}</PrimaryText>
            <PrimaryText className='adminRowTextAddress' variant='body1'>{listing.address}</PrimaryText>
            <PrimaryText className='adminRowText' variant='body1'>{listing.scraped?"Scraped":"Not Scraped"}</PrimaryText>

            {!emailSent && <PrimaryText className='adminRowText' variant='body1'>{"Not Contacted"}</PrimaryText>}
            {emailSent && <PrimaryText className='adminRowText' variant='body1'>{"Contacted: "+emailSentDate.toLocaleDateString("en-IE")}</PrimaryText>}
            
            {listing.hasEmail && <PrimaryText className='adminRowText' variant='body1'>{listing.hasEmail?"Has Email":"No Email"}</PrimaryText>}
            {!listing.hasEmail && <PrimaryText className='adminRowText' variant='body1'>{listing.website===""?"No Website":"Has Website"}</PrimaryText>}
            
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={sendEmail} disabled={!listing.hasEmail}>Send Email</PurpleOutlineButton>
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={createGym}>Create Gym</PurpleOutlineButton>
            <PurpleOutlineButton className='adminRowBtn' variant='outlined' size='small' onClick={visitDB}>Open DB</PurpleOutlineButton>


            <Box className='portalEditIssue'>
                    <FormControl fullWidth>
                        <InputLabel id="issueLabel">Issue</InputLabel>
                        <Select
                            labelId="issueLabel"
                            id="demo-simple-select"
                            value={issue}
                            label="Issue"
                            onChange={(e) => issueChanged(e.target.value)}
                        >
                        <MenuItem value={'None'}>None</MenuItem>
                        <MenuItem value={'Not interested'}>Not interested</MenuItem>
                        <MenuItem value={'No passes'}>No passes</MenuItem>
                        <MenuItem value={'Invalid Business'}>Invalid Business</MenuItem>
                        <MenuItem value={'Email Bounced'}>Email Bounced</MenuItem>
                        <MenuItem value={'Duplicate Email'}>Duplicate Email</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

            
        </div>
    )
}