

import { EmailOutlined, Error, Password, Visibility, VisibilityOff } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../components/mui/Buttons';
import { TextInput } from '../components/mui/Inputs';
import { ErrorText, GreenText, PrimaryText } from '../components/mui/Typography';
import { auth } from '..';
import { signInWithEmailAndPassword,onAuthStateChanged } from 'firebase/auth';
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import ReactLoading from 'react-loading';

import '../styling/login.css'
import axios from "axios";

export default function Login({}) {
    
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    // const [answer,setAnswer] = useState(question.answer || "")
    const [emailText,setEmailText] = useState("")
    const [passwordText,setPasswordText] = useState("")
    const [confirmPasswordText,setConfirmPasswordText] = useState("")
    
    const [successText,setSuccessText] = useState("")
    const [errorText,setErrorText] = useState("")
    const [forgotPassword,setForgotPassword] = useState(false)

    const [resetDetails,setResetDetails] = useState(false)
    const [gym,setGym] = useState(null)

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [loading,setLoading] = useState(false);
    const [updatingLogin,setUpdateLogin] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                successfulAuth(user.uid)
            } else {
                console.log("No user sorry")
            }
          })
          return () => unsubscribe()
        
      }, []);

    const signIn = () => {
        

        setErrorText("")
        setSuccessText("")

        var loginEmail = emailText

        if(loginEmail.includes("temp") && !loginEmail.includes("@")){
            loginEmail = loginEmail+"@travelfitnesspass.com"
        }

        signInWithEmailAndPassword(auth,  loginEmail, passwordText)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("USER", user)
            successfulAuth(user.uid)
        })
        .catch((error) => {
            // An error happened.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("ERRORTT", errorCode)
            if(errorCode === "auth/invalid-email"){setErrorText("Error: Invalid email or username structure")}
            if(errorCode === "auth/user-not-found"){setErrorText("Error: No account exists with this email or username")}
            if(errorCode === "auth/wrong-password"){setErrorText("Error: This is the incorrect password for this account")}
        });
    }

    const successfulAuth = async(uid) => {

        setLoading(true)

        axios.post(baseURL+'getClientAccountInfo',{
        
            uid:uid,

        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
            setLoading(false)
            if(resVar.admin)
            {
                console.log("ADMIN")
                navigate("../viewListings/", {state:{}});
            }
            else{

            let gym = resVar.gyms[0]
            
            
                if(gym.loginUpdated){
                    navigate("../listing/"+gym.id, {state:{gym:gym}});
                }
                else{
                    setGym(gym)
                    setEmailText("")
                    setPasswordText("")
                    setConfirmPasswordText("")
                    setResetDetails(true)
                }
            }

            
        })
        

    }

    const updateLoginDetails = () => {
        updateLogin()
    }

    const updateLogin = async() => {

        let uid = auth.currentUser.uid
        setUpdateLogin(true)

        axios.post(baseURL+'updateClientLogin',{
        
            uid:uid,
            gymID:gym.id,
            email:emailText,
            password:passwordText

        }).then((response) => {
            setUpdateLogin(false)
            let resVar = response.data
            console.log("Response UL", resVar)
            navigate("../listing/"+gym.id, {state:{gym:gym}});
        })
        

    }

    const backToLoginClicked = () => {
        setForgotPassword(false)
        setEmailText("")
        setPasswordText("")
        setErrorText("")
        setSuccessText("")
    }
    
    const forgotPasswordClicked = () => {
        setForgotPassword(true)
        setEmailText("")
        setPasswordText("")
        setErrorText("")
        setSuccessText("")
    }

    const isUpdateBtnDisabled = () => {
        if(emailText==="" || !validateEmail(emailText)){return true}
        else if(passwordText === "" || passwordText.length<6){return true}
        else if(passwordText !== confirmPasswordText){return true}
        else{return false}
    }

    
    
    const validateEmail = (email) => {

        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      
    // const validateEmailUsername = (email) => {

    //     if(email.length > 4 && !email.contains("temp"))
    //     {
    //         return String(email)
    //         .toLowerCase()
    //         .match(
    //           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //         );
    //     }else{
    //         return false
    //     }

        
    //   };

    
      


    return(

        <div className="loginScreen">
            

            <div className="fullScreen">
                <div className="container">
                    <div className="loginMainSection">

                        <div className="loginLeftSide">

                            {(!forgotPassword && !resetDetails) && <div className="loginLeftContainer">
                                <div className="loginHeaders">
                                    <PrimaryText variant="h3">LOG IN</PrimaryText>
                                    <PrimaryText variant="subtitle">Manage your passes in your account</PrimaryText>
                                </div>

                                <div className="loginInputs">
                                    <TextInput className="loginEmailInput" variant="outlined" placeholder="Email or Username"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailOutlined />
                                        </InputAdornment>
                                        ),
                                    }} 
                                    value={emailText} onChange={(e) => setEmailText(e.target.value)}></TextInput>
                                
                                    <TextInput className="loginPasswordInput" type="password" variant="outlined" placeholder="Password"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <Password/>
                                        </InputAdornment>
                                        ),
                                    }}  
                                    value={passwordText} onChange={(e) => setPasswordText(e.target.value)}></TextInput>

                                </div>

                                <div className="loginSubmitSection">
                                    <PurpleButton variant="contained" size="medium" onClick={signIn}
                                    disabled={emailText==="" || passwordText===""}>
                                        SIGN IN</PurpleButton>
                                    <span className="loginForgotPassword" onClick={forgotPasswordClicked}>Forgot Password?</span>
                                </div>

                                <ErrorText variant="body">{errorText}</ErrorText>

                               
                            </div>
                            }








                            

                            {(forgotPassword && !resetDetails) && 
                                <div className="loginLeftContainer">
                                <div className="loginHeaders">
                                    <PrimaryText variant="h3">RECOVER PASSWORD</PrimaryText>
                                    <PrimaryText variant="subtitle">Don't worry, this happens to the best of us.</PrimaryText>
                                </div>

                                <div className="loginInputs">
                                    <TextInput className="loginEmailInput" variant="outlined" placeholder="Enter email for recovery link"
                                    
                                    value={emailText} onChange={(e) => setEmailText(e.target.value)}></TextInput>
                                

                                </div>

                                <div className="loginSubmitSection">
                                    <PurpleButton variant="contained" size="medium" 
                                    // onClick={resetPassword}
                                    disabled={emailText===""}>
                                        SEND RECOVERY LINK</PurpleButton>
                                        <span className="loginForgotPassword" onClick={backToLoginClicked}>Return to Login</span>

                                </div>


                                <ErrorText variant="body">{errorText}</ErrorText>
                                <GreenText variant="body">{successText}</GreenText>

                            </div>
                            }



                            {resetDetails && 
                                <div className="loginLeftContainer">
                                <div className="loginHeaders">
                                    <PrimaryText variant="h3">UPDATE DETAILS</PrimaryText>
                                    <PrimaryText variant="subtitle">To access your account, you must set your own login details.</PrimaryText>
                                </div>

                                <div className="loginInputs updateDetails">
                                    
                                <TextInput className="loginEmailInput" variant="outlined" label="Email"
                                    error={emailText.length>0 && !validateEmail(emailText)}
                                    helperText={(emailText.length>0 && !validateEmail(emailText))?"Invalid email":""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    {(emailText.length>0 && !validateEmail(emailText)) && <Error className="inputErrorIcon"/>}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                    }} 
                                    value={emailText} onChange={(e) => setEmailText(e.target.value)}></TextInput>
                                
                                    <TextInput className="loginEmailInput" type={showPassword ? "text" : "password"}  variant="outlined" label="Password*"
                                        error = {passwordText.length<6 && passwordText.length>0}
                                        helperText = {passwordText.length<6 && passwordText.length>0?"Password must be a minimum of 6 characters long":""}
                                        InputProps={{ 
                                            // startAdornment: (
                                            //     <InputAdornment position="start">
                                            //         <Password/>
                                            //     </InputAdornment>
                                            //     ),
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                        value={passwordText} onChange={(e) => setPasswordText(e.target.value)}
                                    />

                                    <TextInput className="loginEmailInput" type={showPassword ? "text" : "password"} variant="outlined" label="Confirm password*"
                                        error = {passwordText!==confirmPasswordText  && confirmPasswordText.length>0}
                                        helperText = {passwordText!==confirmPasswordText  && confirmPasswordText.length>0?"Passwords do not match":""}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                           
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                        value={confirmPasswordText} onChange={(e) => setConfirmPasswordText(e.target.value)}
                                    />

                                </div>

                                <div className="loginSubmitSection">
                                    <PurpleButton variant="contained" size="medium" 
                                    onClick={updateLoginDetails}
                                    disabled={isUpdateBtnDisabled()}>
                                        UPDATE</PurpleButton>
                                </div>

                                <ErrorText variant="body">{errorText}</ErrorText>
                                <GreenText variant="body">{successText}</GreenText>

                            </div>
                            }


                            

                        </div>

                        <div className="loginRightSide">
                                <img className="loginScreenIcon" src="/assets/loginScreenIcon.png"/>

                        </div>





                    </div>
                </div>
            </div>

            
            {loading && <div className='loginLoading'>
                <div className='loginLoadingBox'>
                    <PrimaryText variant='h4'>Loading Account</PrimaryText>
                    <CircularProgress sx={{color:'#766CE3', height:'500'}} className='loginLoadingCircle'/>
                </div>
            </div>}

            {updatingLogin && <div className='loginLoading'>
                <div className='loginLoadingBox'>
                    <PrimaryText variant='h4'>Updating Login</PrimaryText>
                    <CircularProgress sx={{color:'#766CE3', height:'500'}} className='loginLoadingCircle'/>
                </div>
            </div>}
            
            
            
    
            
        </div>
    )
}