import { Typography } from "@mui/material";
import { styled, textAlign } from "@mui/system";


export const PrimaryText = styled(Typography)({
    color:'#151329',
    textAlign:'left',
    
    "&.MuiTypography-h1":{
        fontWeight:'600',
        fontSize:'60px'
    },

    "&.MuiTypography-h2":{
        fontWeight:'600',
        fontSize:'48px'
    },

    "&.MuiTypography-h3":{
        fontWeight:'600',
        fontSize:'32px'
    },

    "&.MuiTypography-h4":{
        fontWeight:'700',
        fontSize:'20px'
    },

    "&.MuiTypography-subtitle1":{
        fontWeight:'700',
        fontSize:'18px'
    },

    "&.MuiTypography-subtitle2":{
        fontWeight:'500',
        fontSize:'16px'
    },

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    "&.MuiTypography-body2":{
        fontWeight:'400',
        fontSize:'14px'
    },
    "&.MuiTypography-caption":{
        fontWeight:'400',
        fontSize:'12px'
    },

    
})

export const PrimaryTextRight = styled(Typography)({
    color:'#151329',
    textAlign:'right',
    

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    
    
})



export const SecondaryText = styled(Typography)({
    color:'#666666',
    textAlign:'left',
    
    "&.MuiTypography-h1":{
        fontWeight:'600',
        fontSize:'60px'
    },

    "&.MuiTypography-h2":{
        fontWeight:'600',
        fontSize:'48px'
    },

    "&.MuiTypography-h3":{
        fontWeight:'600',
        fontSize:'32px'
    },

    "&.MuiTypography-h4":{
        fontWeight:'700',
        fontSize:'20px'
    },

    "&.MuiTypography-subtitle1":{
        fontWeight:'700',
        fontSize:'18px'
    },

    "&.MuiTypography-subtitle2":{
        fontWeight:'500',
        fontSize:'16px'
    },

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    "&.MuiTypography-body2":{
        fontWeight:'400',
        fontSize:'14px'
    },
    "&.MuiTypography-caption":{
        fontWeight:'400',
        fontSize:'12px'
    },
})



export const DisabledText = styled(Typography)({
    color:'#AAAAAA',
    textAlign:'left',
    
    "&.MuiTypography-h1":{
        fontWeight:'600',
        fontSize:'60px'
    },

    "&.MuiTypography-h2":{
        fontWeight:'600',
        fontSize:'48px'
    },

    "&.MuiTypography-h3":{
        fontWeight:'600',
        fontSize:'32px'
    },

    "&.MuiTypography-h4":{
        fontWeight:'700',
        fontSize:'20px'
    },

    "&.MuiTypography-subtitle1":{
        fontWeight:'700',
        fontSize:'18px'
    },

    "&.MuiTypography-subtitle2":{
        fontWeight:'500',
        fontSize:'16px'
    },

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    "&.MuiTypography-body2":{
        fontWeight:'400',
        fontSize:'14px'
    },
    "&.MuiTypography-caption":{
        fontWeight:'400',
        fontSize:'12px'
    },
})



export const PurpleText = styled(Typography)({
    color:'#766CE3',
    textAlign:'left',
    
    "&.MuiTypography-h1":{
        fontWeight:'600',
        fontSize:'60px'
    },

    "&.MuiTypography-h2":{
        fontWeight:'600',
        fontSize:'48px'
    },

    "&.MuiTypography-h3":{
        fontWeight:'600',
        fontSize:'32px'
    },

    "&.MuiTypography-h4":{
        fontWeight:'700',
        fontSize:'20px'
    },

    "&.MuiTypography-subtitle1":{
        fontWeight:'700',
        fontSize:'18px'
    },

    "&.MuiTypography-subtitle2":{
        fontWeight:'500',
        fontSize:'16px'
    },

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    "&.MuiTypography-body2":{
        fontWeight:'400',
        fontSize:'14px'
    },
    "&.MuiTypography-caption":{
        fontWeight:'400',
        fontSize:'12px'
    },
})





export const GreenText = styled(Typography)({
    color:'#83C17B',
    textAlign:'left',
    
    "&.MuiTypography-h1":{
        fontWeight:'600',
        fontSize:'60px'
    },

    "&.MuiTypography-h2":{
        fontWeight:'600',
        fontSize:'48px'
    },

    "&.MuiTypography-h3":{
        fontWeight:'600',
        fontSize:'32px'
    },

    "&.MuiTypography-h4":{
        fontWeight:'700',
        fontSize:'20px'
    },

    "&.MuiTypography-subtitle1":{
        fontWeight:'700',
        fontSize:'18px'
    },

    "&.MuiTypography-subtitle2":{
        fontWeight:'500',
        fontSize:'16px'
    },

    "&.MuiTypography-subtitle3":{
        fontWeight:'600',
        fontSize:'17px'
    },

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    "&.MuiTypography-body2":{
        fontWeight:'400',
        fontSize:'14px'
    },
    "&.MuiTypography-caption":{
        fontWeight:'400',
        fontSize:'12px'
    },
})




export const ErrorText = styled(Typography)({
    color:'#E16794',
    textAlign:'left',
    
    "&.MuiTypography-h1":{
        fontWeight:'600',
        fontSize:'60px'
    },

    "&.MuiTypography-h2":{
        fontWeight:'600',
        fontSize:'48px'
    },

    "&.MuiTypography-h3":{
        fontWeight:'600',
        fontSize:'32px'
    },

    "&.MuiTypography-h4":{
        fontWeight:'700',
        fontSize:'20px'
    },

    "&.MuiTypography-subtitle1":{
        fontWeight:'700',
        fontSize:'18px'
    },

    "&.MuiTypography-subtitle3":{
        fontWeight:'600',
        fontSize:'17px'
    },

    "&.MuiTypography-subtitle2":{
        fontWeight:'500',
        fontSize:'16px'
    },

    "&.MuiTypography-body1":{
        fontWeight:'400',
        fontSize:'16px'
    },

    "&.MuiTypography-body2":{
        fontWeight:'400',
        fontSize:'14px'
    },
    "&.MuiTypography-caption":{
        fontWeight:'400',
        fontSize:'12px'
    },

    
})