import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Autocomplete } from '@mui/material';
import { TextInput } from '../mui/Inputs';
import '../../styling/admin.css'
import { PrimaryText } from '../mui/Typography';
import { PurpleButton } from '../mui/Buttons';

export default function AdminSearch({search}) {

    const [places,setAllPlaces] = useState({})
    
    const [countryOptions,setCountryOptions] = useState([])
    const [cityOptions,setCityOptions] = useState([])

    const [country,setCountry] = useState("")
    const [city,setCity] = useState("")
    
    
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    
   
    useEffect(() => {
        getListOfPlacesFromDB()
      }, []);

    useEffect(() => {
        if(country!=="")
        {
            setCityOptions(places[country])
            setCity("")
        }
        
    }, [country]);

    
    const getListOfPlacesFromDB = () => {

        axios.get(baseURL+'getScrapedCities',{
        

        }).then((response) => {
            
            let resVar = response.data
            setAllPlaces(resVar)

            let countries = []
            for (let key in resVar) {
                countries.push(key)
            }
            setCountryOptions(countries)

        })
    }

      
    return(

        <div className='adminSearch'>
            <PrimaryText variant='h4'>Select City</PrimaryText>
            <div className='adminSearchOptions'>
                <Autocomplete className='portalEditCountry' disablePortal defaultValue={country} options={countryOptions} renderInput={(params)=><TextInput {...params} variant="outlined" label="Country" value={country}/>} onChange={(e) => setCountry(e.target.textContent)}/>
                <Autocomplete className='portalEditCountry' disablePortal defaultValue={city} options={cityOptions} renderInput={(params)=><TextInput {...params} variant="outlined" label="City" value={city}/>} onChange={(e) => setCity(e.target.textContent)}/>
            </div>
            <PurpleButton variant='contained' disabled={city===""} onClick={()=>search(city,country)}>Search</PurpleButton>
        
        </div>
    )
}