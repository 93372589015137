

import { AddCircle, Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { PurpleCheckBox, TextInput } from '../mui/Inputs';
import { ErrorText, PrimaryText, SecondaryText } from '../mui/Typography';
import OpenHourRowEL from './OpenHourRowEL';





export default function OpenHourDayEL({index, gym ,updated}) {

    
    
    const getGymTimes = () => {
        var timesArray = []
    
            let gymTimes = gym.openingHours[index].times


            gymTimes.forEach(timeWindow => {
                let open = new Date("2023-01-01 "+timeWindow.open) //(Date.parse(timeWindow.open))
                let close = new Date("2023-01-01 "+timeWindow.close) //(Date.parse(timeWindow.close))
                timesArray.push({open:open,close:close})
            });
            return timesArray
    }
    
    const dayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

    const [hoursRows,setHoursRows] = useState(gym!==null?getGymTimes():[{open:new Date(2022, 12, 20, 8, 0),close:new Date(2007, 12, 20, 22, 0)}])
    const [updateVar,setUpdateVar] = useState(0)

    const [open,setOpen] = useState(gym!==null?gym.openingHours[index].open:true)
    
    // useEffect(() => {
        
    //     var timesArray = []
    //     if(gym!==null)
    //     {
    //         let gymTimes = gym.openingHours[index].times
    //         console.log("GYM TIMES", gymTimes)

    //         gymTimes.forEach(timeWindow => {
    //             let open = new Date(Date.parse(timeWindow.open))
    //             let close = new Date(Date.parse(timeWindow.close))
    //             timesArray.push({open:open,close:close})
    //         });
    //     }
    //     console.log("UPDATED TIMES",timesArray)
    //     setHoursRows(timesArray)
    //     setUpdateVar(updateVar+1)
        
    //   }, []);


     
    useEffect(() => {
        
        updated(index,open,hoursRows,dayNames[index])
        
      }, [open,updateVar]);



    
    const showHourRows = () => {
        let hourArray = []
        var index = 0


        hoursRows.forEach(row => {
            console.log("ROW",row)
            let hourRow = 
                <OpenHourRowEL 
                    index={index} 
                    openP={row.open} 
                    closeP={row.close} 
                    edit={editHourRow}
                    deleteFunc={deleteHourRow}
                />
            hourArray.push(hourRow)
            index++
        });

        return hourArray
    }



    const addHourRow = () => {
        hoursRows.push({open:new Date(2022, 12, 20, 9, 0),close:new Date(2007, 12, 20, 17, 0)})
        setHoursRows(hoursRows)
        setUpdateVar(updateVar+1)
    }

    
    const editHourRow = (index,value) => {


        console.log("EHR",value)

        hoursRows[index] = value
        setHoursRows(hoursRows)
        setUpdateVar(updateVar+1)
        
    }

    const deleteHourRow = (index) => {
        hoursRows.splice(index, 1)
        setHoursRows(hoursRows)
        setUpdateVar(updateVar+1)
    }

    const checkBoxChanged = (event,value) => {
        let isChecked = event.target.checked 
        setOpen(isChecked)
    }

    return(

        <div className='openHourDayEL'>
            <PrimaryText variant='subtitle1'>{dayNames[index]}</PrimaryText>

            <div className='openHourDayRow'>


                <div className='multipleChoiceAnswer'>
                    <PrimaryText variant='subtitle2'>Open:</PrimaryText>
                    <PurpleCheckBox  onChange={event =>checkBoxChanged(event)} checked={open}/>
                    
                </div>

                {open && <div className='openHourDayTimes'>
                    {showHourRows()}

                    <div className={`addOHRow`} onClick={addHourRow}>
                        <div className={`addOHRowContent`}>
                        <AddCircle />
                        <SecondaryText variant="body1">Add TimeSlot</SecondaryText>
                        </div>
                    </div>

                </div>}

                {!open && <ErrorText variant='subtitle2'>Set as closed</ErrorText>}

            </div>

            
        </div>
    )
}