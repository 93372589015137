

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Autocomplete, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { PurpleSwitch, TextInput } from '../mui/Inputs';
import { GreenText, PrimaryText, PurpleText, SecondaryText } from '../mui/Typography';
import GymRulesEL from './GymRulesEL';
import MultipleChoiceEL from './MultipleChoiceEL';
import OpenHourRowEL from './OpenHourRowEL';
import axios from "axios";
import { auth } from '../..';
import { type } from '@testing-library/user-event/dist/type';





export default function FacilityInfoEL({gym}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    
    const [open4,setOpen4] = useState(false)

    const [wcAccess,setWCAccess] = useState(gym!==null?gym.wheelchairAccess:false)
    const [womenOnly,setWomenOnly] = useState(gym!==null?gym.womenOnly:false)

    const [amenities,setAmenities] = useState(gym!==null?gym.amenities:[])
    const [equipment,setEquipment] = useState(gym!==null?gym.equipment:[])

    const [bio,setBio] = useState(gym!==null?gym.bio:"")

    const [gymRules,setGymRules] = useState(gym!==null?gym.generalRequirements:[""])
    
    const [currentlyUpdating,setCurrentlyUpdating] = useState(false)
    const [updatedSuccess,setUpdatedSuccess] = useState(false)

    const [facilityType,setFacilityType] = useState(gym.facilityType!==undefined?gym.facilityType:"")
    
    const typeOptions = ["Commercial Gym","CrossFit","Sports Centre","Boxing/Martial Arts","Powerlifting","Climbing Centre","Personal Trainer","Yoga/Pilates Studio"]
    
    const updatetDB = () => {
        
        let gymID = gym.id
        let uid = auth.currentUser.uid

        setUpdatedSuccess(false)
        setCurrentlyUpdating(true)

        var updatedRules = []
        gymRules.forEach(rule => {
            if(rule !== ""){updatedRules.push(rule)}
        });
        

        axios.post(baseURL+'editListingFacilityInfo',{
        
            uid:uid,
            gymID:gymID,
            
            gymRules:updatedRules,
            equipment:equipment,
            amenities:amenities,
            wcAccess:wcAccess,
            bio:bio,
            type:facilityType,
            womenOnly:womenOnly
        
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)

            setUpdatedSuccess(true)
            setCurrentlyUpdating(false)
        })
    }


    return(

        <Accordion className='accordionEL' TransitionProps={{ unmountOnExit: true }} expanded={open4}>
            <AccordionSummary onClick={()=>setOpen4(!open4)}>
                <div className='portalEditBlockTop'>
                    <div>
                        <PrimaryText variant='h4'>Facility Info</PrimaryText>
                        {open4 && <SecondaryText variant='subtitle2'>Help users learn more about your facility</SecondaryText>}
                    </div>
                    {open4 && <ExpandLess />}
                    {!open4 && <ExpandMore />}
                </div>
            </AccordionSummary>
            <AccordionDetails>
            <div className='portalEditSec1Content'>

            <div className='portalEditRow'>

                <div className='portalEditColumn'>
                    <div className='portalEditSubtitles'>
                        <SecondaryText variant='subtitle1'>Type of facility</SecondaryText>
                        <SecondaryText variant='subtitle2'>Please choose which type below best describes your facility</SecondaryText>
                    </div>


                    <Autocomplete className='portalEditFacility' disablePortal defaultValue={facilityType} options={typeOptions} renderInput={(params)=><TextInput {...params} variant="outlined" label="Facility Type" value={"Angola"} />} onChange={(e) => setFacilityType(e.target.textContent)}/>
                </div>

                <div className='portalEditColumn'>
                        <div className='portalEditSubtitles'>
                            <SecondaryText variant='subtitle1'>Women Only</SecondaryText>
                            <SecondaryText variant='subtitle2'>Is your facility only for females?</SecondaryText>
                        </div>


                        <div className='wcAccessSwitchRow'>
                            <PrimaryText variant='subtitle2'>No</PrimaryText>
                            <PurpleSwitch checked={womenOnly} onChange={(e)=>setWomenOnly(e.target.checked)}/>
                            <PrimaryText variant='subtitle2'>Yes</PrimaryText>
                        </div>         

                </div>
            </div>

            <div className='portalEditColumn facilities'>
                <div className='portalEditSubtitles'>
                    <SecondaryText variant='subtitle1'>Equipment</SecondaryText>
                    <SecondaryText variant='subtitle2'>List any rules your facility has in place (eg. Hand-towels mandatory)</SecondaryText>
                </div>


                <MultipleChoiceEL type={"equipment"} answersP={equipment} returnAnswer={(answers)=>setEquipment(answers)}/>
            </div>

            <div className='portalEditColumn facilities'>
                <div className='portalEditSubtitles'>
                    <SecondaryText variant='subtitle1'>Amenities</SecondaryText>
                    <SecondaryText variant='subtitle2'>List any rules your facility has in place (eg. Hand-towels mandatory)</SecondaryText>
                </div>


                <MultipleChoiceEL type={"amenities"} answersP={amenities} returnAnswer={(answers)=>setAmenities(answers)}/>
            </div>


            <div className='portalEditRow'>

            


            
                <div className='portalEditColumn '>


                    <div className='portalEditColumn'>
                            <div className='portalEditSubtitles'>
                                <SecondaryText variant='subtitle1'>Bio</SecondaryText>
                                <SecondaryText variant='subtitle2'>Tell us a little bit about your company</SecondaryText>
                            </div>


                            <TextInput className='portalEditInput' variant="outlined" label="Input bio"
                                multiline rows={5}
                                value={bio} onChange={(e) => setBio(e.target.value)}/>

                                   

                    </div>

                    <div className='portalEditColumn'>
                            <div className='portalEditSubtitles'>
                                <SecondaryText variant='subtitle1'>Accessibility</SecondaryText>
                                <SecondaryText variant='subtitle2'>Is your facility wheelchair accessible?</SecondaryText>
                            </div>


                            <div className='wcAccessSwitchRow'>
                                <PrimaryText variant='subtitle2'>No</PrimaryText>
                                <PurpleSwitch checked={wcAccess} onChange={(e)=>setWCAccess(e.target.checked)}/>
                                <PrimaryText variant='subtitle2'>Yes</PrimaryText>
                            </div>         

                    </div>


                    

                </div>                                                    
                
            <GymRulesEL rulesP={gymRules} updated={(rules)=>setGymRules(rules)}/>

            </div>

            

            </div>
            </AccordionDetails>

            <AccordionActions>
                {currentlyUpdating && <PurpleText variant='body2' className='updatedSuccessfully'>Updating...</PurpleText>}
                {updatedSuccess && <GreenText variant='body2' className='updatedSuccessfully'>Data updated successfully</GreenText>}
                <PurpleButton size='large' variant='contained' onClick={updatetDB}>SAVE</PurpleButton>
            </AccordionActions>

        </Accordion>
    )
}