

import { Error, ExpandLess, ExpandMore, Facebook, Instagram, Language, LinkedIn, Twitter } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { GreenText, PrimaryText, PurpleText, SecondaryText } from '../mui/Typography';
import axios from "axios";
import { auth } from '../..';




export default function SocialMediaEL({gym}) {
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    // const [answer,setAnswer] = useState(question.answer || "")
    const [open3,setOpen3] = useState(false)

    const [websiteText,setWebsiteText] = useState(gym!==null?gym.socialMedia.website:"")
    const [facebookText,setFacebookText] = useState(gym!==null?gym.socialMedia.facebook:"")
    const [instagramText,setInstagramText] = useState(gym!==null?gym.socialMedia.instagram:"")
    const [twitterText,setTwitterText] = useState(gym!==null?gym.socialMedia.twitter:"")
    const [linkedInText,setLinkedInText] = useState(gym!==null?gym.socialMedia.linkedin:"")
    const [tiktokText,setTikTokText] = useState(gym!==null?gym.socialMedia.tiktok:"")


    const [buttonDisabled,setButtonDisabled] = useState(false)

    const [currentlyUpdating,setCurrentlyUpdating] = useState(false)
    const [updatedSuccess,setUpdatedSuccess] = useState(false)

    
    useEffect(() => {
        
        let disabled = disableButton()
        setButtonDisabled(disabled)
        
      }, [websiteText,facebookText,instagramText,twitterText,tiktokText,linkedInText]);



    const validateLink = (linkText) => {
        let url;
        try {
            url = new URL(linkText);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }


    
    const disableButton = () => {
        if(websiteText.length>0 && !validateLink(websiteText)){return true}
        else if(facebookText.length>0 && !validateLink(facebookText)){return true}
        else if(instagramText.length>0 && !validateLink(instagramText)){return true}
        else if(twitterText.length>0 && !validateLink(twitterText)){return true}
        else if(tiktokText.length>0 && !validateLink(tiktokText)){return true}
        else if(linkedInText.length>0 && !validateLink(linkedInText)){return true}
        else{
            return false
        }
   }

   
   const checkFields = () => {
        let uid = auth.currentUser.uid
        updatetDB(uid)
   }
   
   const updatetDB = (uid) => {
       
        setUpdatedSuccess(false)
        setCurrentlyUpdating(true)

       let gymID = "a9E3bzpU9cwllmHzaIxO"

       axios.post(baseURL+'editListingSocialMedia',{
       
           uid:uid,
           gymID:gymID,
           website:websiteText,
           facebook:facebookText,
           instagram:instagramText,
           twitter:twitterText,
           linkedin:linkedInText,
           tiktok:tiktokText

       
       }).then((response) => {
            setUpdatedSuccess(true)
           let resVar = response.data
           console.log("Response", resVar)
       })
   }


    return(

        <Accordion className='accordionEL' TransitionProps={{ unmountOnExit: true }} expanded={open3}>
            <AccordionSummary onClick={()=>setOpen3(!open3)}>
                <div className='portalEditBlockTop'>
                    <div>
                        <PrimaryText variant='h4'>Social Media</PrimaryText>
                        {open3 && <SecondaryText variant='subtitle2'>Where can users find out more about you?</SecondaryText>}
                    </div>
                    
                    {open3 && <ExpandLess />}
                    {!open3 && <ExpandMore />}
                </div>
            </AccordionSummary>
            <AccordionDetails>

            <div className='socialRow'>



                <div className='portalEditColumn socialColumn'>

                <TextInput 
                    className='socialInput'
                    type="text" 
                    error={websiteText.length>0 && !validateLink(websiteText)}
                    helperText={(websiteText.length>0 && !validateLink(websiteText))?"Invalid Link":""}
                    variant="outlined" 
                    label="Website URL"
                    placeholder='https://website.com'
                    value={websiteText} onChange={(e) => setWebsiteText(e.target.value)}
                    InputProps={{ // <-- This is where the toggle button is added.
                        startAdornment: (
                            <InputAdornment position="start">
                                <Language />
                            </InputAdornment>
                            ),
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                {(websiteText.length>0 && !validateLink(websiteText)) && <Error className="inputErrorIcon"/>}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    />
                
                
                <TextInput 
                    className='socialInput'
                    type="text" 
                    error={facebookText.length>0 && !validateLink(facebookText)}
                    helperText={(facebookText.length>0 && !validateLink(facebookText))?"Invalid Link":""}
                    variant="outlined" 
                    label="Facebook URL"
                    placeholder='https://facebook.com'
                    value={facebookText} onChange={(e) => setFacebookText(e.target.value)}
                    InputProps={{ // <-- This is where the toggle button is added.
                        startAdornment: (
                            <InputAdornment position="start">
                                <Facebook />
                            </InputAdornment>
                            ),
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                {(facebookText.length>0 && !validateLink(facebookText)) && <Error className="inputErrorIcon"/>}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    />

                    <TextInput 
                        className='socialInput'
                        type="text" 
                        error={instagramText.length>0 && !validateLink(instagramText)}
                        helperText={(instagramText.length>0 && !validateLink(instagramText))?"Invalid Link":""}
                        variant="outlined" 
                        label="Instagram URL"
                        placeholder='https://instagram.com'
                        value={instagramText} onChange={(e) => setInstagramText(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Instagram />
                                </InputAdornment>
                                ),
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    {(instagramText.length>0 && !validateLink(instagramText)) && <Error className="inputErrorIcon"/>}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />

                                                        </div>

                <div className='portalEditColumn socialColumn'>

                
                

                    <TextInput 
                        className='socialInput'
                        type="text" 
                        error={twitterText.length>0 && !validateLink(twitterText)}
                        helperText={(twitterText.length>0 && !validateLink(twitterText))?"Invalid Link":""}
                        variant="outlined" 
                        label="Twitter URL"
                        placeholder='https://twitter.com'
                        value={twitterText} onChange={(e) => setTwitterText(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Twitter />
                                </InputAdornment>
                                ),
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    {(twitterText.length>0 && !validateLink(twitterText)) && <Error className="inputErrorIcon"/>}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />

                    <TextInput 
                        className='socialInput'
                        type="text" 
                        error={linkedInText.length>0 && !validateLink(linkedInText)}
                        helperText={(linkedInText.length>0 && !validateLink(linkedInText))?"Invalid Link":""}
                        variant="outlined" 
                        label="LinkedIn URL"
                        placeholder='https://linkedin.com'
                        value={linkedInText} onChange={(e) => setLinkedInText(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LinkedIn />
                                </InputAdornment>
                                ),
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    {(linkedInText.length>0 && !validateLink(linkedInText)) && <Error className="inputErrorIcon"/>}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />

                    <TextInput 
                        className='socialInput'
                        type="text" 
                        error={tiktokText.length>0 && !validateLink(tiktokText)}
                        helperText={(tiktokText.length>0 && !validateLink(tiktokText))?"Invalid Link":""}
                        variant="outlined" 
                        label="TikTok URL"
                        placeholder='https://tiktok.com'
                        value={tiktokText} onChange={(e) => setTikTokText(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img className='socialIcon' src='/assets/tiktok.png'/>
                                </InputAdornment>
                                ),
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    {(tiktokText.length>0 && !validateLink(tiktokText)) && <Error className="inputErrorIcon"/>}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />
                    </div>

            </div>

                

                    
            </AccordionDetails>

            <AccordionActions>
                {currentlyUpdating && <PurpleText variant='body2' className='updatedSuccessfully'>Updating...</PurpleText>}
                {updatedSuccess && <GreenText variant='body2' className='updatedSuccessfully'>Data updated successfully</GreenText>}
                <PurpleButton size='large' variant='contained' disabled={buttonDisabled || currentlyUpdating} onClick={checkFields}>SAVE</PurpleButton>
            </AccordionActions>

        </Accordion>
    )
}