import React, { useEffect, useState } from 'react';
import AdminSearch from '../../components/admin/AdminSearch';
import axios from "axios";
import AdminFilter from '../../components/admin/AdminFilters';
import AdminScrapedRow from '../../components/admin/AdminScrapedRow';
import { PurpleButton } from '../../components/mui/Buttons';
import { PrimaryText } from '../../components/mui/Typography';
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import { auth } from '../..';
import { onAuthStateChanged } from '@firebase/auth';



export default function ViewScrapedResults({}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [allListings,setAllListings] = useState([])
    const [filteredListings,setFilteredListings] = useState([])
    
    const [selectedListings,setSelectedListings] = useState([])
    const [updateVar,setUpdateVar] = useState(0)

    const navigate = useNavigate();

    useEffect(() => {
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if(!user.uid.includes('LriAMmPV7'))
                {
                    navigate("../login", {state:{}});
                }
                console.log("USER",user.uid)
            } else {
                console.log("No user sorry")
                navigate("../login", {state:{}});
            }
          })
          return () => unsubscribe()
        
      }, []);
   
    useEffect(() => {

      }, []);


      const getListingsFromDB = (city,country) => {
        axios.get(baseURL+'getScrapedResultsByCity',{
        
            params:{
                city:city,
                country:country
            }

        }).then((response) => {
            
            let resVar = response.data
            
            console.log("LISTINGS",resVar)
            setAllListings(resVar)
            setFilteredListings(resVar)
        })
      }

      const sendEmails = () => {

        let selectedGyms = allListings.filter(function(gym) {
            return selectedListings.includes(gym.id)
        })

        axios.post(baseURL+'sendGymsInitialEmail',{
        
            gyms:selectedGyms

        }).then((response) => {
            
            let resVar = response.data
            console.log("CERATED GYMs",resVar)

            updateContacted()
            setSelectedListings([])
            setUpdateVar(updateVar+1)
        })
    }

    const setContacted = () => {

        axios.post(baseURL+'setGymsContacted',{
        
            scrapedIDs:selectedListings

        }).then((response) => {
            
            let resVar = response.data
            console.log("SET CONTACTED",resVar)

            updateContacted()
            setSelectedListings([])
            setUpdateVar(updateVar+1)
        })
    }

    const updateContacted = () => {
        filteredListings.forEach(gym => {
            if(selectedListings.includes(gym.id))
            {
                gym.messaged = true
            }
        });
        setFilteredListings(filteredListings)

    }

    const testFunc = () =>  {
        updateContacted()
        setSelectedListings([])
        setUpdateVar(updateVar+1)
    }


      const createGyms = () => {

        axios.post(baseURL+'convertMultipleScrapedListings',{
        
            scrapedIDs:selectedListings

        }).then((response) => {
            
            let resVar = response.data
            console.log("CERATED GYMs",resVar)

        })
    }

    
    const showListings = () => {

        let listingsArray = []
        var index = 1
        filteredListings.forEach(listing => {
            let thisIndex = index
            let checked =  selectedListings.includes(listing.id)
            listingsArray.push(<AdminScrapedRow listing={listing} index={thisIndex} updated={updateSelected} checkedP={checked}/>)
            index++
        });

        return listingsArray
    }

    const updateSelected = (id,selected) => {
        if(selected){selectedListings.push(id)}
        else{ 
            const index = selectedListings.indexOf(id);
            selectedListings.splice(index, 1)
        }

        console.log("Selected Listings",selectedListings)
        setSelectedListings(selectedListings)
        setUpdateVar(updateVar+1)
    }



    const viewListings = () => {
        navigate("../viewListings", {state:{}});
    }

      
    return(

        <div className='viewScrapedResults'>
            
            <div className='adminHeader'>
                <PrimaryText variant='h3'>Scraped Results</PrimaryText>
                <PurpleButton onClick={viewListings}>View Gym Listings</PurpleButton>
            </div>


            <AdminSearch search={getListingsFromDB}/>
            {allListings.length>0 && <AdminFilter 
                listings={allListings} 
                clear={()=>setFilteredListings(allListings)}
                updated={(listings)=>setFilteredListings(listings)}
            />}
            
            <div className='adminTable'>
             {showListings()} 
            </div>
                

            {filteredListings.length>0 && <div className='adminBtnRow'>
                <PurpleButton onClick={sendEmails}>Send Emails</PurpleButton>             
                <PurpleButton onClick={createGyms}>Create Gyms</PurpleButton>      
                <PurpleButton onClick={setContacted}>Set Contacted</PurpleButton>       
 
            </div>}

            

        </div>
    )
}