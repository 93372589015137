import React, { useEffect, useState } from 'react';
import '../styling/misc.css'
import { PrimaryText, PurpleText } from '../components/mui/Typography';
import { CheckCircle } from '@mui/icons-material';

export default function StripeConnect() {

    const [places,setAllPlaces] = useState({})

    
    
    return(

        <div className='stripeConnectScreen'>
            <PurpleText variant='h3'>Stripe Sign Up Complete</PurpleText>
            <CheckCircle className='stripeConnectTick' sx={{fontSize:'75px'}}/>
            <span className='stripeConnectSubtext'>A member of our team will be in touch with you in the coming hours.</span>
            <span className='stripeConnectSubtext'>Please click refresh button in the Edit General Info section to view update.</span>

        </div>
    )
}