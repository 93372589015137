import React, { useEffect, useState } from 'react';
import AdminSearch from '../../components/admin/AdminSearch';
import axios from "axios";
import AdminFilter from '../../components/admin/AdminFilters';
import AdminScrapedRow from '../../components/admin/AdminScrapedRow';
import { PurpleButton } from '../../components/mui/Buttons';
import AdminListingRow from '../../components/admin/AdminListingRow';
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import { PrimaryText } from '../../components/mui/Typography';
import { auth } from '../..';
import { onAuthStateChanged } from '@firebase/auth';



export default function ViewGymListings({}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [allListings,setAllListings] = useState([])
    const [filteredListings,setFilteredListings] = useState([])
    
    const [selectedListings,setSelectedListings] = useState([])
    const [updateVar,setUpdateVar] = useState(0)

    const navigate = useNavigate();


    useEffect(() => {
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if(!user.uid.includes('LriAMmPV7'))
                {
                    navigate("../login", {state:{}});
                }
                console.log("USER",user.uid)
            } else {
                console.log("No user sorry")
                navigate("../login", {state:{}});
            }
          })
          return () => unsubscribe()
        
      }, []);
   
    useEffect(() => {

      }, []);


    const getListingsFromDB = (city,country) => {
    axios.get(baseURL+'getGymsByCityAdmin',{
    
        params:{
            city:city,
            country:country
        }

    }).then((response) => {
        
        let resVar = response.data
        
        console.log("LISTINGS",resVar)
        setAllListings(resVar)
        setFilteredListings(resVar)
    })
    }

    const makeGymsLive = () => {

        axios.post(baseURL+'makeMultipleGymsLive',{
        
            gyms:selectedListings

        }).then((response) => {
            
            let resVar = response.data
            console.log("CERATED GYMs",resVar)

        })
    }

    const createAccounts = () => {

        axios.post(baseURL+'makeMultipleGymAccounts',{
        
            gymIDs:selectedListings

        }).then((response) => {
            
            let resVar = response.data
            console.log("CERATED ACcounts",resVar)

        })
    }


    
    const showListings = () => {

        let listingsArray = []
        var index = 1
        filteredListings.forEach(listing => {
            let thisIndex = index
            let checked =  selectedListings.includes(listing.id)

            listingsArray.push(<AdminListingRow listing={listing} index={thisIndex} updated={updateSelected} city={listing.city} checkedP={checked}/>)
            index++
        });

        return listingsArray
    }

    const updateSelected = (id,selected) => {
        if(selected){selectedListings.push(id)}
        else{ 
            const index = selectedListings.indexOf(id);
            selectedListings.splice(index, 1)
        }

        console.log("Selected Listings",selectedListings)
        setSelectedListings(selectedListings)
    }

    const viewScraped = () => {
        navigate("../viewScraped", {state:{}});
    }

      
    return(

        <div className='viewScrapedResults'>
            
            <div className='adminHeader'>
                <PrimaryText variant='h3'>Gym Listings</PrimaryText>
                <PurpleButton onClick={viewScraped}>View Scraped Results</PurpleButton>
            </div>

            <AdminSearch search={getListingsFromDB}/>
            {allListings.length>0 && <AdminFilter 
                listings={allListings} 
                clear={()=>setFilteredListings(allListings)}
                updated={(listings)=>setFilteredListings(listings)}
            />}

            {showListings()}     

        {filteredListings.length>0 && <div className='adminBtnRow'>
            <PurpleButton onClick={makeGymsLive}>Make Live</PurpleButton>       
            <PurpleButton onClick={createAccounts}>Create Accounts</PurpleButton>       
        </div>}

            
        </div>
    )
}