

import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';





export default function PassInfoRow({index,nameP,daysP,priceP,currencyP,currencyChanged,edit,deleteFunc}) {

    const [name,setName] = useState(nameP)
    const [days,setDays] = useState(daysP)
    const [price,setPrice] = useState(priceP)
    const [formattedPrice,setFormattedPrice] = useState(priceP)
    const [currency,setCurrency] = useState(currencyP)
    
    const formatPrice = () => {
        console.log("PRICE",price)
        setPrice((+price).toFixed(2))
        setFormattedPrice((+price).toFixed(2))
    }

    

    useEffect(() => {
        edit(index,{name:name,days:days,price:formattedPrice})
      }, [name,days,formattedPrice,currency]);

      useEffect(() => {
        setName(nameP)
        setDays(daysP)
        setPrice((+priceP).toFixed(2))
        setCurrency(currencyP)
      }, [nameP,daysP,priceP,currencyP]);



      const currencies = [
        {
          value: 'EUR',
          label: '€',
        },
        {
          value: 'GBP',
          label: '£',
        },
        {
          value: 'USD',
          label: '$',
        },
        {
          value: 'CAD',
          label: 'C$',
        },
        {
          value: 'AUD',
          label: 'A$',
        },
        {
          value: 'JPY',
          label: '¥',
        },


        {
          value: 'CZK',
          label: 'Kč',
        },
        {
          value: 'DKK',
          label: 'kr.',
        },
        {
          value: 'HUF',
          label: 'Ft.',
        },
        {
          value: 'NOK',
          label: 'kr.',
        },
        {
          value: 'SEK',
          label: 'kr.',
        },
        {
          value: 'CHF',
          label: 'CHF',
        },
        {
          value: 'TRY',
          label: '₺',
        },

      ];
      

      useEffect(() => {
        
        currencyChanged(currency)
        
      }, [currency]);


    return(

        <div className='passInfoRow'>
            <div className={`portalEditPassRow ${index==0?'':'bin'}`}>
                <TextInput className='portalEditInput' variant="outlined" label="Pass Name"
                    value={name} onChange={(e) => setName(e.target.value)}/>

                <TextInput className='portalEditPassDuration'variant="outlined" label="Duration (Days)"
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                        }}
                    value={days} onChange={(e) => setDays(e.target.value)}/>

                

                
                

                <TextInput className='portalEditInput' variant="outlined" label={"Price ("+currency+")"}
                type="number" onBlur={formatPrice}
                    value={price} onChange={(e) => setPrice((e.target.value))}/>


                <TextInput
                    className='portalEditCurrency'
                    id="outlined-select-currency"
                    select
                    label="Currency"
                    value={currency}
                    onChange={(e) => setCurrency((e.target.value))}
                    // helperText="Please select your currency"
                    >
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                </TextInput>


                {index!==0 && <Delete className='binIcon' onClick={() => deleteFunc(index)}/>}
                



            </div>

            {index!==0 && <Box className='binIconMobile'><Delete onClick={() => deleteFunc(index)}/></Box>}


        </div>
    )
}