import { PrimaryText } from "../../components/mui/Typography";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PurpleButton } from "../../components/mui/Buttons";
import EmailLogAdd from "../../components/admin/EmailLogAdd";
import EmailLogRow from "../../components/admin/EmailLogRow";

export default function EmailLog({}) {

    const [logList,setLogList] = useState([])
    const [updateVar,setUpdateVar] = useState(0)

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const loadListings = () => {

        axios.get(baseURL+'getListingEmailLogs',{

        }).then((response) => {
            
            let resVar = response.data
            setLogList(resVar.emailLogs)
            setUpdateVar(updateVar+1)
        })
    }

    const showListings = () => {
        let listingsArray = []
        var index = 1
        logList.forEach(listing => {
            let thisIndex = index

            listingsArray.push(<EmailLogRow listing={listing} index={thisIndex} deleteFunc={deleteLogRow}/>)
            index++
        });

        console.log("Listing Array",listingsArray)
        return listingsArray
    }

    const addListing = (id,gymID,name,template,country) => {
        logList.push({
            id:id,
            gymID:gymID,
            gymName:name,
            country:country,
            template:template,
            responseType:"No Response"
        })

        setLogList(logList)
        setUpdateVar(updateVar+1)
    }

    
    const deleteLogRow = (index,listing) => {
        logList.splice(index, 1)
        setLogList(logList)
        setUpdateVar(updateVar+1)
    }

    return(
        <div className="viewScrapedResults">
            <PrimaryText variant="h3">Email Logs</PrimaryText>
            
            {logList.length>0 && <div className="emailListingTable">
                {showListings()}
            </div>}

            {logList.length===0 && <PurpleButton variant="contained" onClick={loadListings}>Load Listings</PurpleButton>}

            <EmailLogAdd added={addListing}/>

        </div>
    )

}