import logo from './logo.svg';
import './App.css';
import { getAuth,createUserWithEmailAndPassword,onAuthStateChanged } from "firebase/auth";
import {BrowserRouter as Router, Route,Routes,Navigate, Link} from 'react-router-dom'
import EditListing from './screens/EditListing';
import Login from './screens/Login';
import ViewGymListings from './screens/Admin/ViewGymListings';
import ViewScrapedResults from './screens/Admin/ViewScrapedResults';
import Header from './components/Header';
import React, { useEffect, useState } from 'react';
import { auth } from '.';
import StripeConnect from './screens/StripeConnect';
import EmailLog from './screens/Admin/EmailLog';

function App() {


  const [loggedIn, setLoggedIn] = useState(false)
  

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Auth change", true,user,loggedIn)
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }
    })
    return () => unsubscribe()

  },[])


  const login = () => {
    console.log("LOGIN CLICKED")
    // const auth = getAuth();
    
    createUserWithEmailAndPassword(auth,  "cgm@gmail.com",123456)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log("USER", user)     
    })
    .catch((error) => {
        // An error happened.
        const errorMessage = error.message;
        console.log("ERROR", errorMessage)
        // setLoginError(true)
        // setLoginErrorMessage(errorMessage)
    });
}


  return (
    <div className="App">
      
      <Router>

        <Header loggedIn={loggedIn}/>        

        <Routes>
          <Route path='/'  element={<Login />} />
          <Route path='/login'  element={<Login />} />
          <Route path='/listing/:gymID'  element={<EditListing />} />

          <Route path='/viewListings'  element={<ViewGymListings />} />
          <Route path='/viewScraped'  element={<ViewScrapedResults />} />
          <Route path='/emailLog'  element={<EmailLog />} />

          <Route path='/stripe-connect'  element={<StripeConnect />} />
          <Route path='/stripe_connect'  element={<StripeConnect />} />
        </Routes>



      </Router>

      

      
    </div>
  );
}

export default App;
