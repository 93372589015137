

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { GreenText, PrimaryText, PurpleText, SecondaryText } from '../mui/Typography';
import MultipleChoiceEL from './MultipleChoiceEL';
import OpenHourRowEL from './OpenHourRowEL';
import axios from "axios";
import OpenHourDayEL from './OpenHourDayEL';
import { auth } from '../..';




export default function OpeningHoursEL({gym}) {

    // const [answer,setAnswer] = useState(question.answer || "")
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    const [open4,setOpen4] = useState(false)

    const [daysList, setDaysList] = useState([0,1,2,3,4,5,6])

    const [currentlyUpdating,setCurrentlyUpdating] = useState(false)
    const [updatedSuccess,setUpdatedSuccess] = useState(false)
   
   const checkFields = () => {
        let uid = auth.currentUser.uid
        updatetDB(uid)
    }

    const updatetDB = (uid) => {
    
        let gymID = gym.id

        setUpdatedSuccess(false)
        setCurrentlyUpdating(true)

        let times = convertDayList(daysList)

        axios.post(baseURL+'editListingOpenHours',{
        
            uid:uid,
            gymID:gymID,
            openingHours:times

        
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)

            setUpdatedSuccess(true)
            setCurrentlyUpdating(false)
        })
    }

    
    const showDays = () => {

        let dayArray = []
        var index = 0


        while(index<7){
            let day = 
                <OpenHourDayEL 
                    index={index} 
                    gym={gym}
                    updated={dayUpdated}
                />
            dayArray.push(day)
            index++
        }

        return dayArray
    }
    

    const dayUpdated = (index,openB,openHours,dayName) => {

        var times = null
        if(openB){times = openHours}
        
        daysList[index] = {name:dayName,open:openB,times:times}

    }


    const convertDayList = (daysList) => {

        let newTimes = []


        daysList.forEach(day => {
            var timeArray = []
            if(day.open)
            {
                day.times.forEach(time => {
                    let open = convertDateToTime(time.open)
                    let close = convertDateToTime(time.close)
                    timeArray.push({open:open,close:close})
                });
            }

            newTimes.push({name:day.name,open:day.open,times:timeArray})
        });

        return newTimes
    }


    const convertDateToTime = (date) =>
    {
        let dateVar = new Date(date)
        var hours = dateVar.getHours()
        if(hours < 10){hours = "0"+hours}
        var minutes = dateVar.getMinutes()
        if(minutes < 10){minutes = "0"+minutes}

        return(hours+":"+minutes)
    }



    return(

        <Accordion className='accordionEL' TransitionProps={{ unmountOnExit: true }} expanded={open4}>
            <AccordionSummary onClick={()=>setOpen4(!open4)}>
                <div className='portalEditBlockTop'>
                    <div>
                        <PrimaryText variant='h4'>Opening Hours</PrimaryText>
                        {open4 && <SecondaryText variant='subtitle2'>What times can user's use your facility</SecondaryText>}
                    </div>
                    {open4 && <ExpandLess />}
                    {!open4 && <ExpandMore />}
                </div>
            </AccordionSummary>
            <AccordionDetails className='accordionContent'>
            <div className='portalEditSec1Content'>
            

                                                    
                <div className='portalEditColumn'>

                    <div className='portalEditSubtitles'>
                        <SecondaryText variant='subtitle1'></SecondaryText>
                        <SecondaryText variant='subtitle2'></SecondaryText>
                    </div>
                    
                    <div className='openHourSecEL'>
                        
                        {showDays()}

                    </div>
                    
                </div>




            

            

            </div>
            </AccordionDetails>

            <AccordionActions>
                {currentlyUpdating && <PurpleText variant='body2' className='updatedSuccessfully'>Updating...</PurpleText>}
                {updatedSuccess && <GreenText variant='body2' className='updatedSuccessfully'>Data updated successfully</GreenText>}
                <PurpleButton size='large' variant='contained' onClick={checkFields} disabled={currentlyUpdating}>SAVE</PurpleButton>
            </AccordionActions>

        </Accordion>
    )
}