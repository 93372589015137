

import { AddCircle, Delete, ExpandLess, ExpandMore, RemoveCircle } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { GreyButton, PurpleButton, PurpleOutlineButton, RedButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { getDownloadURL, getStorage, ref,uploadBytes,deleteObject   } from "firebase/storage";
import { uuidv4 } from '@firebase/util';
import { storage } from '../..';





export default function ImageUploaderTile({index,imageP,uploaded,setPrimary,deleted,gymID}) {

    
    const inputFile = useRef(null) 

    const [image,setImage] = useState(imageP)
    

    useEffect(() => {
        setImage(imageP)
      }, [imageP]);


    const openFileExplorer = () => {
        inputFile.current.click();
    }

    const fileChanged = (event) => {
        console.log("FILE CHANGED",event)
        let file = event.target.files[0]
    
        let date = new Date()
        let time = date.getTime()


        const storageRef = ref(storage, 'gyms/'+gymID+'/image'+time+'.jpg');
        const metadata = {
            contentType: 'image/jpg',
            metadata: {
                firebaseStorageDownloadTokens: uuidv4()
              }
            
          };

        uploadBytes(storageRef, file,metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!',snapshot);
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                uploaded(index,downloadURL)
              });
          });

    }


    const deleteImage = () => {

        try{

            
        var storageRef = ref(storage,image)
        
        inputFile.current.value = null

        deleteObject(storageRef).then(() => {
            // File deleted successfully
            deleted(index)
            

          }).catch((error) => {
            console.log("Delete Image Error",error)
            // Uh-oh, an error occurred!
          });
        }catch(e)
        {
            if(e.code === 'storage/invalid-url' && image.includes("googleusercontent"))
            {
                deleted(index)
            }
        }



        
    }

    return(

        <div className='imageUploaderTile'>
            
            {image === null && <div className='newImageTile' onClick={openFileExplorer}>
                <AddCircle />
            </div>}


            {image !== null && <div className='existingImageTile'>
                <img className='imageTileImage' src={image}/>

                <PurpleButton className='imageTileBtn' variant='contained' disabled={index === 0} onClick={()=>setPrimary(index)}>Set as primary image</PurpleButton>
                <PurpleOutlineButton className='imageTileBtn' variant='outlined' onClick={openFileExplorer}>Change image</PurpleOutlineButton>
                {/* <RedButton className='imageTileBtn'>Remove image</RedButton> */}
                
                {index === 0 && <div className='imageTilePrimary'>Primary image</div>}
                {index !== 0 && <RemoveCircle className='imageTileDelete' onClick={deleteImage}/>}

            </div>}

            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept="image/*" onChange={fileChanged}/>


        </div>
    )
}