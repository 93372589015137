import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { color } from '@mui/system';


export const PurpleButton = styled(Button)({
    backgroundColor:'#766CE3',
    color: 'white',
    borderColor:'white',
    // height:'42px',
    // width:'100px',

    ':hover':{
        backgroundColor:'#5B54B0',
        borderColor:'white'
    },
    ':disabled':{
        backgroundColor:'#DDDDE3',
        color:'#959599'
    },
    ':pressed':{
        backgroundColor:'#766CE3',
    }
})



export const GreenButton = styled(Button)({
    backgroundColor:'#A7F2CE',
    variant:'medium',
})


export const GreenButton2 = styled(Button)({
    backgroundColor:'#83C17B',
    variant:'medium',
    ':hover':{
        backgroundColor:'#70A16A'
    }
})


export const RedButton = styled(Button)({
    backgroundColor:'red',
    color: 'white',
    variant:'medium',
    ':hover':{
        backgroundColor:'#F97C93'
    }
})




export const GreyButton = styled(Button)({
    backgroundColor:'#F2F3FD',
    color: 'white',
    variant:'medium',
    ':hover':{
        backgroundColor:'#A39FDB'
    }
})


export const PurpleOutlineButton = styled(Button)({
    borderColor:'#766CE3',
    color:'#766CE3',
    ':hover':{
        backgroundColor:'#DFDDFE33'
    }
})