import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfigPortal = {
  apiKey: "AIzaSyCcsxYOzzWquN9dQWU8nhsgimDk4Psbsxk",
  authDomain: "tfp-portal-ee7cc.firebaseapp.com",
  projectId: "tfp-portal-ee7cc",
  storageBucket: "tfp-portal-ee7cc.appspot.com",
  messagingSenderId: "298935152398",
  appId: "1:298935152398:web:efa675debe52b7328d315f",
  measurementId: "G-T67K352D5L"
};


const firebaseConfig = {
  apiKey: "AIzaSyC49TrxlvYHYMmbx1AhhB3j-a0iUN9ebNU",
  authDomain: "travel-fitness-pass.firebaseapp.com",
  projectId: "travel-fitness-pass",
  storageBucket: "travel-fitness-pass.appspot.com",
  messagingSenderId: "359308616683",
  appId: "1:359308616683:web:74d2b499972358a1c22fae",
  measurementId: "G-NQQXZEF51V"
};


// Initialize Firebase
const app = initializeApp(firebaseConfigPortal);
const app2 = initializeApp(firebaseConfig,"secondary");
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app2)


export {auth,storage}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
