import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton, PurpleOutlineButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { PrimaryText } from '../mui/Typography';



export default function AdminFilter({listings,clear,updated}) {

    const [allListings,setAllListings] = useState(listings)

    // const [filteredListings,setFilteredListings] = useState([])
    
    const [scraped,setScraped] = useState(null)
    const [hasEmail,setHasEmail] = useState(null)
    const [messaged,setMessaged] = useState(null)
    const [hasWebsite,setHasWebsite] = useState(null)
    
   
    useEffect(() => {
        setAllListings(listings)
      }, [listings]);


    const clearFilters = () => {
        clear()
        setScraped(null)
        setHasEmail(null)
        setMessaged(null)
    }

    const applyFilters = () => {

        var filteredListings = allListings

        if(scraped !== null)
        {
            if(scraped)
            {
                filteredListings = filteredListings.filter(function(gym) {
                    return gym.scraped
                })
            }
            else{
                filteredListings = filteredListings.filter(function(gym) {
                    return !gym.scraped
                })
            }
        }


        if(messaged !== null)
        {

            if(messaged)
            {
                filteredListings = filteredListings.filter(function(gym) {
                    return gym.messaged
                })
            }
            else{
                filteredListings = filteredListings.filter(function(gym) {
                    return !gym.messaged
                })
            }
            console.log("FILTERED MESSAGED", filteredListings)

        }

        if(hasEmail !== null)
        {
            if(hasEmail)
            {
                filteredListings = filteredListings.filter(function(gym) {
                    return gym.hasEmail
                })
            }
            else{
                filteredListings = filteredListings.filter(function(gym) {
                    return !gym.hasEmail
                })
            }
            console.log("FILTERED EMAIL", filteredListings)

        }

        if(hasWebsite !== null)
        {
            if(hasWebsite)
            {
                filteredListings = filteredListings.filter(function(gym) {
                    return gym.website !== ""
                })
            }
            else{
                filteredListings = filteredListings.filter(function(gym) {
                    return gym.website === ""
                })
            }
        }
        


        console.log("FILTERED LSITINGS",filteredListings)
        updated(filteredListings)
        
    }
      
    return(

        <div className='adminFilters'>
           
           <PrimaryText variant='h4'>Filters</PrimaryText>

           <div className='adminFiltersRow'>

            <Box className='adminFilter'>
                    <FormControl fullWidth>
                        <InputLabel id="scrapedLabel">Been Scraped</InputLabel>
                        <Select
                            labelId="scrapedLabel"
                            id="demo-simple-select"
                            value={scraped}
                            label="Been Scraped"
                            onChange={(e) => setScraped(e.target.value)}
                        >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

                <Box className='adminFilter'>
                    <FormControl fullWidth>
                        <InputLabel id="messagedLabel">Messaged</InputLabel>
                        <Select
                            labelId="messagedLabel"
                            id="demo-simple-select"
                            value={messaged}
                            label="Messaged"
                            onChange={(e) => setMessaged(e.target.value)}
                        >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>

                        </Select>
                    </FormControl>
                </Box>


                <Box className='adminFilter'>
                    <FormControl fullWidth>
                        <InputLabel id="emailLabel">Has Email</InputLabel>
                        <Select
                            labelId="emailLabel"
                            id="demo-simple-select"
                            value={hasEmail}
                            label="Has Email"
                            onChange={(e) => setHasEmail(e.target.value)}
                        >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

                

                
                <Box className='adminFilter'>
                    <FormControl fullWidth>
                        <InputLabel id="websiteLabel">Has Website</InputLabel>
                        <Select
                            labelId="websiteLabel"
                            id="demo-simple-select"
                            value={hasWebsite}
                            label="Has Website"
                            onChange={(e) => setHasWebsite(e.target.value)}
                        >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

            </div>

            <div className='adminFiltersRow'>

                <PurpleButton variant='contained' onClick={applyFilters}>Apply</PurpleButton>
                <PurpleOutlineButton variant='outlined' onClick={clearFilters}>Clear Filters</PurpleOutlineButton>

            </div>
            

        </div>
    )
}