

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { GreenText, PrimaryText, SecondaryText } from '../mui/Typography';
import ImageUploaderTile from './ImageUploaderTile';
import axios from "axios";
import { auth } from '../..';




export default function ImagesEL({gym}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    // const [answer,setAnswer] = useState(question.answer || "")
    const [open2,setOpen2] = useState(false)


    // const [imageList,setImageList] = useState([
    //     'https://firebasestorage.googleapis.com/v0/b/travel-fitness-pass.appspot.com/o/test%2FgymPics%2FgymTest1.jpg?alt=media&token=16c995eb-5609-4d1a-85ec-0953235d7559',
    //     'https://firebasestorage.googleapis.com/v0/b/travel-fitness-pass.appspot.com/o/test%2FgymPics%2FgymTest6.jpg?alt=media&token=7d6b607e-c3c0-4e3e-9c97-dd234345ce56',
    //     'https://firebasestorage.googleapis.com/v0/b/travel-fitness-pass.appspot.com/o/test%2FgymPics%2FgymTest2.jpg?alt=media&token=647c0cbf-0466-40df-837d-6221f653c11d',
    //     'https://firebasestorage.googleapis.com/v0/b/travel-fitness-pass.appspot.com/o/test%2FgymPics%2Fmark-bertulfo-XKimW0pke6w-unsplash.jpg?alt=media&token=a1e74896-18ee-47ab-bbe4-9f242df7369f'
    // ])

    
    const [imageList,setImageList] = useState(gym!==null?gym.images:[])

    const [updateVar,setUpdateVar] = useState(0)

    const [buttonDisabled,setButtonDisabled] = useState(false)
    const [updatedSuccess,setUpdatedSuccess] = useState(false)

    const showImageGrid = () => {

        var imageTileArray = []
        var index = 0
        imageList.forEach(image => {
            imageTileArray.push(
                <ImageUploaderTile 
                    index={index} 
                    imageP={image} 
                    uploaded={imageUploaded}
                    setPrimary={imagePrimary}
                    deleted={imageDeleted}
                    gymID={gym.id}
                />
            )
            index++
        });

        if(imageTileArray.length<5)
        {
            imageTileArray.push(<ImageUploaderTile index={-1} imageP={null} uploaded={imageUploaded} gymID={gym.id}/>)
        }
        

        return imageTileArray
    }
    

    const imageUploaded = (index,image) => {

        if(index === -1){
            imageList.push(image)
        }
        else{
            imageList[index] = image
        }

        setImageList(imageList)
        setUpdateVar(updateVar+1)
        updatetDB('',imageList)
    }

    const imageDeleted = (index) => {
        imageList.splice(index, 1)
        setImageList(imageList)
        setUpdateVar(updateVar+1)
        updatetDB('',imageList)
    }

    const imagePrimary = (index) => {
        
        imageList.unshift(imageList.splice(index, 1)[0]);
        setImageList(imageList)
        setUpdateVar(updateVar+1)
        updatetDB('',imageList)
    }



    

   
   const checkFields = () => {
        updatetDB()
   }
   
   const updatetDB = (uidP,images) => {
       
        setUpdatedSuccess(false)
       let gymID = gym.id
       let uid = auth.currentUser.uid


       axios.post(baseURL+'editListingImages',{
       
           uid:uid,
           gymID:gymID,
           images:images

       
       }).then((response) => {
            setUpdatedSuccess(true)
           let resVar = response.data
           console.log("Response", resVar)
       })
   }





    return(
        <Accordion className='accordionEL' TransitionProps={{ unmountOnExit: true }} expanded={open2}>
            <AccordionSummary onClick={()=>setOpen2(!open2)}>
                <div className='portalEditBlockTop'>
                    <div>
                        <PrimaryText variant='h4'>Images</PrimaryText>
                        {open2 && <SecondaryText variant='subtitle2'>Add images to appear on your profile (Max. 5 images)</SecondaryText>}
                    </div>
                    {open2 && <ExpandLess />}
                    {!open2 && <ExpandMore/>}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className='editImagesGrid'>
                    {showImageGrid()}
                </div>
                
            </AccordionDetails>

            <AccordionActions>
                {updatedSuccess && <GreenText variant='body2' className='updatedSuccessfully'>Data updated successfully</GreenText>}
                {/* <PurpleButton size='large' variant='contained' disabled={buttonDisabled} onClick={checkFields}>SAVE</PurpleButton> */}
            </AccordionActions>

        </Accordion>
    )
}