

import { AddCircle, Delete, ExpandLess, ExpandMore, Inbox } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from '../mui/Buttons';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';





export default function GymRulesEL({rulesP,updated}) {

    const [rules,setRules] = useState(rulesP)
    const [updateVar,setUpdateVar] = useState(0)
    
   
    const showRules = () => {

        let rulesArray = []
        var index = 0

        rules.forEach(rule => {
            let thisIndex = index
            let input = <div className='gymRuleRow'>
                <div className={`portalEditOHRow bin`}>


                    <TextInput className='portalEditInput' variant="outlined" label="Input rule"
                        value={rule} onChange={(e) => updateRule(e.target.value,thisIndex)}/>


                    <Box className='binIcon'><Delete onClick={() => deleteFunc(thisIndex)}/></Box>
                    <Box className='binIconMobile'><Delete onClick={() => deleteFunc(thisIndex)}/></Box>
                </div>

            </div>
                        
            

            rulesArray.push(input)
            index++
        });

        return rulesArray
        
    }

    const updateRule = (text, index) => {
        rules[index] = text
        setRules(rules)
        updated(rules)
        setUpdateVar(updateVar+1)
    }

    
    const deleteFunc = (index) => {
        console.log("DELKETE",index)
        rules.splice(index, 1)
        setRules(rules)
        updated(rules)
        setUpdateVar(updateVar+1)
    }

    const addRule = () => {
        rules.push("")
        setRules(rules)
        updated(rules)
        setUpdateVar(updateVar+1)
    }


    return(

        <div className='portalEditColumn'>
            <div className='portalEditSubtitles'>
                <SecondaryText variant='subtitle1'>Gym Rules</SecondaryText>
                <SecondaryText variant='subtitle2'>List any rules your facility has in place (eg. Hand-towels mandatory)</SecondaryText>
            </div>


            
            
            {showRules()}

            {/* <PurpleButton size='medium' onClick={addRule}>Add Rule</PurpleButton> */}

            <div className={` gymRules`} onClick={addRule}>
                <div className={`addPassRowContent`}>
                <AddCircle />
                <SecondaryText variant="body1">Add Rule</SecondaryText>
                </div>
            </div>
        </div>
    )
}