import { Autocomplete, Checkbox, FormControlLabel, Switch, TextField } from "@mui/material";
import { color, styled } from "@mui/system";
import MuiPhoneNumber from 'material-ui-phone-number';

export const TextInput = styled(TextField)({

  backgroundColor:"white",


    "& .MuiOutlinedInput-root": {
        
        "&.Mui-focused fieldset": {
          borderColor: "#766CE3",
          color:"#766CE3",
        }
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: "#766CE3",
      }
})



export const PhoneInput = styled(MuiPhoneNumber)({
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#766CE3",
          color:"#766CE3",
        }
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: "#766CE3",
      }
})


export const AutoCompleteInput = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#766CE3",
          color:"#766CE3",
        }
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: "#766CE3",
      },

    //   "& .MuiInputLabel-root.Mui-focused": {
    //     color: "#766CE3",
    //   }
})


export const PurpleCheckBox = styled(Checkbox)({

  color:'#72717C',
  height:'15px',
  width:'15px',

  "&.Mui-checked":{
    color:"#766CE3",
  }
})


export const PurpleSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#766CE3',
    '&:hover': {
      backgroundColor: '#F2F3FD',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#766CE3',
  },
});